import React from 'react'
import { KpiPromptContextProvider } from 'contexts/KpiPromptContext'
import { KpiPrompt } from 'components/kpi/KpiPrompt'

export const Kpi = () => {
  return (
    <KpiPromptContextProvider>
      <KpiPrompt />
    </KpiPromptContextProvider>
  )
}
