import Card from 'components/common/Card'
import React, { useContext, useEffect } from 'react'
import { IMetric } from 'interfaces/Kpi'
import { useTranslation } from 'react-i18next'
import { KpiPromptContext } from 'contexts/KpiPromptContext'
import { usePromptKpi } from 'hooks/usePromptKpi'
import { differenceInDays } from 'date-fns'

export const KpiCard: React.FC<{ metric: IMetric }> = ({ metric }) => {
  const { t } = useTranslation()
  const { getSumByField, checkIfEmpty, formattedTime } = usePromptKpi()
  const {
    activeMetric,
    setActiveMetric,
    kpis,
    oldKpis,
    selectedPrompts,
    end,
    start,
  } = useContext(KpiPromptContext)
  const isEmptyKpi = checkIfEmpty(kpis)
  const isEmptyOldKpi = checkIfEmpty(oldKpis)
  const total = getSumByField(kpis, metric.totalField)
  const totalOld = getSumByField(oldKpis, metric.totalField)
  const changeMetric = (metric: IMetric) => {
    setActiveMetric(metric)
  }
  const Icon = metric.icon
  const [diviseur, setDiviseur] = React.useState(1)
  const [oldDiviseur, setOldDiviseur] = React.useState(1)
  const moyenne = diviseur > 0 ? total / diviseur : 0
  const moyenneOld = oldDiviseur > 0 ? totalOld / oldDiviseur : 0

  useEffect(() => {
    if (metric.type === 'application') {
      setDiviseur(differenceInDays(end, start))
      setOldDiviseur(differenceInDays(end, start))
    } else {
      const divisor = getSumByField(kpis, 'total_conversation')
      setDiviseur(divisor)
      setOldDiviseur(getSumByField(oldKpis, 'total_conversation'))
    }
  }, [activeMetric, end, start, selectedPrompts])
  return (
    <Card
      className={`kpi-card group ${activeMetric.type === metric.type ? 'active' : ''}`}
      onClick={() => changeMetric(metric)}
    >
      <div className="kpi-card-header-wrapper">
        <div className="kpi-card-header general group-hover:dark:bg-gray-1600 group-hover:bg-white">
          <Icon className="ml-[10px] mr-30px" />
          <span className="text-[16px] font-medium">{t(metric.title)}</span>
        </div>
      </div>
      <div className="kpi-card-content">
        <div className="col col1">
          <span className="uppercase">{t(metric.titleCol1)}</span>
          {isEmptyKpi && isEmptyOldKpi ? (
            <span>{t('prompts.kpi.insufficient')}</span>
          ) : (
            <>
              <span
                className={`${isEmptyKpi ? 'text-[13px] text-gray-1250' : totalOld > 0 ? (totalOld > total ? 'text-[18px] text-red-1250' : 'text-[18px] text-blue-1500') : 'text-[18px]'}`}
              >
                <>
                  {isEmptyKpi
                    ? t('prompts.kpi.insufficient')
                    : metric.type !== 'traitement'
                      ? total
                      : formattedTime(total)}
                  {!isEmptyKpi && metric.type === 'cout' ? '€' : ''}
                </>
              </span>
              {!isEmptyOldKpi && (
                <span>
                  <>
                    {metric.type !== 'traitement'
                      ? totalOld
                      : formattedTime(totalOld)}
                    {metric?.type === 'cout' ? '€' : ''}
                  </>
                </span>
              )}
            </>
          )}
        </div>
        <div className="col">
          <span className="uppercase">{t(metric.titleCol2)}</span>
          {isEmptyKpi && isEmptyOldKpi ? (
            <span>{t('prompts.kpi.insufficient')}</span>
          ) : (
            <>
              <span
                className={`${isEmptyKpi ? 'text-[13px] text-gray-1250' : moyenneOld > 0 ? (moyenneOld > moyenne ? 'text-[18px] text-red-1250' : 'text-[18px] text-blue-1500') : 'text-[18px]'}`}
              >
                {isEmptyKpi
                  ? t('prompts.kpi.insufficient')
                  : metric.type !== 'traitement'
                    ? Math.round(moyenne * 10) / 10
                    : formattedTime(moyenne)}
                {!isEmptyKpi && metric.type === 'cout' ? '€' : ''}
              </span>
              {!isEmptyOldKpi && (
                <span>
                  {metric.type !== 'traitement'
                    ? Math.round(moyenneOld * 10) / 10
                    : formattedTime(moyenneOld)}
                  {!isEmptyKpi && metric.type === 'cout' ? '€' : ''}
                </span>
              )}
            </>
          )}
        </div>
      </div>
    </Card>
  )
}
