import { TooltipProps } from 'recharts'
// eslint-disable-next-line import/no-unresolved
import { Payload } from 'recharts/types/component/DefaultTooltipContent'
export interface CustomTooltipProps extends TooltipProps<number, string> {
  payload?: Payload<number, string>[]
  label?: string
}

export const KpiCustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
}) => {
  if (active && payload && payload.length) {
    return (
      <div className="kpi-custom-tooltip">
        {payload.map((item, index) => (
          <p
            key={index}
          >{`${item.value ? Math.round(item.value * 10) / 10 : '-'} - ${item.payload?.date}`}</p>
        ))}
      </div>
    )
  }
  return null
}
