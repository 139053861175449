import React, { MutableRefObject, useState } from 'react'
import {
  ControllerRenderProps,
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form'
import { PromptForm } from 'interfaces/Prompt'
import { FieldLabel } from 'components/common/FieldLabel'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ConsigneIcon } from 'assets/images/consigneIcon.svg'
import { useAskMutation } from 'api/ask'
import { IAskBody } from 'interfaces/Ask'

export const PromptFormFieldsAssisted: React.FC<{
  tacheField: ControllerRenderProps<PromptForm, 'tache'>
  roleField: ControllerRenderProps<PromptForm, 'role'>
  contexteField: ControllerRenderProps<PromptForm, 'contexte'>
  formatField: ControllerRenderProps<PromptForm, 'format'>
  instructionField: ControllerRenderProps<PromptForm, 'instruction'>
  tacheRef: MutableRefObject<HTMLTextAreaElement | null>
  addTextToTextarea: (
    event: React.MouseEvent,
    text: string,
    ref: MutableRefObject<any>,
    fieldName: string
  ) => void
  errors: any
  setError: UseFormSetError<PromptForm>
  clearErrors: UseFormClearErrors<PromptForm>
  setValue: UseFormSetValue<PromptForm>
  instructionRequired: boolean
  setInstructionRequired: (value: (prevState: boolean) => boolean) => void
}> = ({
  tacheField,
  roleField,
  contexteField,
  formatField,
  instructionField,
  tacheRef,
  addTextToTextarea,
  errors,
  setError,
  clearErrors,
  setValue,
  instructionRequired,
  setInstructionRequired,
}) => {
  const { t } = useTranslation()

  const [showFieldTask, setShowFieldTask] = useState<boolean>(
    roleField.value !== ''
  )
  const [showFieldContext, setShowFieldContext] = useState<boolean>(
    contexteField.value !== ''
  )
  const [ask, { isLoading }] = useAskMutation()

  const handleAsk = (
    event: React.MouseEvent<HTMLButtonElement>,
    field: keyof PromptForm
  ) => {
    event.preventDefault()
    let fieldValue: string | undefined

    clearErrors(field)
    if (field === 'role') {
      fieldValue = roleField.value
    } else if (field === 'tache') {
      fieldValue = tacheField.value ?? '' + contexteField.value ?? ''
    }

    if (fieldValue === '') {
      setError(field, {
        type: 'required',
        message: t('global.form.error.mandatory'),
      })
      return
    }

    const body: IAskBody = {
      application:
        field === 'role'
          ? 'prompt_mode_accompagne_tache'
          : 'prompt_mode_accompagne_contexte',
      params: {
        consigne: JSON.stringify(fieldValue),
      },
    }

    ask(body)
      .unwrap()
      .then((response) => {
        if (field === 'role') {
          setValue('tache', response.answer)
          setShowFieldTask(true)
        } else if (field === 'tache') {
          setValue('contexte', response.answer)
          setShowFieldContext(true)
        }
      })
      .catch((error) => {
        setError(field, {
          type: 'prompt',
          message: error,
        })
      })
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-col">
        <FieldLabel
          className="h-8 md:h-12 md:align-top md:py-3"
          label={t('prompts.form.roleLabel')}
          required
        />
        <div className="h-full flex md:flex-row flex-col w-full">
          <div className="flex flex-col flex-grow">
            <textarea
              placeholder={t('prompts.form.rolePlaceholder')}
              className={`form-textarea h-[150px] w-full md:h-[60px] ${errors.role ? 'error' : ''}`}
              {...roleField}
            />
            {errors.role && (
              <p className="form-error mt-10px">
                {errors.role.type === 'required'
                  ? t('global.form.error.mandatory')
                  : errors.prompt.message}
              </p>
            )}
          </div>
        </div>

        <button
          className={`btn default prompt-default-button ${showFieldTask ? 'prompt-start-again' : ''}`}
          onClick={(event) => handleAsk(event, 'role')}
          disabled={isLoading}
        >
          {showFieldTask
            ? t('prompts.form.button.startAgain')
            : t('prompts.form.button.listTasks')}
          {isLoading && <span className="button-loader ml-2"></span>}
        </button>
      </div>

      {showFieldTask && (
        <div className="flex flex-col mt-[20px]">
          <FieldLabel
            className="h-8 md:h-12 md:align-top md:py-3"
            label={t('prompts.form.tacheLabel')}
            required
          />
          <div className="h-full flex md:flex-row flex-col w-full">
            <div className="flex flex-col flex-grow">
              <textarea
                placeholder={t('prompts.form.tachePlaceholder')}
                className={`form-textarea h-[150px] w-full md:h-[250px] ${errors.tache ? 'error' : ''}`}
                {...tacheField}
                ref={(e) => {
                  tacheField.ref(e)
                  // eslint-disable-next-line no-param-reassign
                  tacheRef.current = e
                }}
              />
              {errors.tache && (
                <p className="form-error mt-10px">
                  {errors.tache.type === 'required'
                    ? t('global.form.error.mandatory')
                    : errors.tache.message}
                </p>
              )}
              <div className="flex flex-col md:flex-row md:justify-start mt-[10px] md:max-h-[45px]">
                <button
                  className="button-consigne"
                  onClick={(event) =>
                    addTextToTextarea(event, '{consigne}', tacheRef, 'tache')
                  }
                >
                  <ConsigneIcon />
                  <span className="flex-grow text-center">
                    {t('prompts.form.button.consigne')}
                  </span>
                </button>
                <p className="flex items-center ml-[10px] text-yellow-1700">
                  {t('prompts.form.checkProposal')}
                </p>
              </div>
            </div>
          </div>

          <button
            className={`btn default prompt-default-button ${showFieldContext ? 'prompt-start-again' : ''}`}
            onClick={(event) => handleAsk(event, 'tache')}
            disabled={isLoading}
          >
            {showFieldContext
              ? t('prompts.form.button.startAgain')
              : t('prompts.form.button.getContext')}
            {isLoading && <span className="button-loader ml-2"></span>}
          </button>
        </div>
      )}

      {showFieldTask && showFieldContext && (
        <div className="flex flex-col ">
          <FieldLabel
            className="h-8 md:h-12 md:align-top md:py-3"
            label={t('prompts.form.contexteLabel')}
            required
          />
          <div className="h-full flex md:flex-row flex-col w-full">
            <div className="flex flex-col flex-grow">
              <textarea
                placeholder={t('prompts.form.contextePlaceholder')}
                className={`form-textarea h-[150px] w-full md:h-[160px] ${errors.contexte ? 'error' : ''}`}
                {...contexteField}
              />
              {errors.contexte && (
                <p className="form-error mt-10px">
                  {errors.contexte.type === 'required'
                    ? t('global.form.error.mandatory')
                    : errors.contexte.message}
                </p>
              )}
              <p className="flex items-center ml-[10px] mt-[10px] text-yellow-1700">
                {t('prompts.form.answerContext')}
              </p>
            </div>
          </div>
        </div>
      )}

      {showFieldTask && showFieldContext && (
        <div className="flex flex-col my-[20px]">
          <FieldLabel
            className="h-8 md:h-12 md:align-top md:py-3"
            label={t('prompts.form.formatLabel')}
            required
          />
          <div className="h-full flex md:flex-row flex-col w-full">
            <div className="flex flex-col flex-grow">
              <textarea
                placeholder={t('prompts.form.formatPlaceholder')}
                className={`form-textarea h-[150px] w-full md:h-[100px] ${errors.format ? 'error' : ''}`}
                {...formatField}
              />
              {errors.format && (
                <p className="form-error mt-10px">
                  {errors.format.type === 'required'
                    ? t('global.form.error.mandatory')
                    : errors.format.message}
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-start my-[20px]">
            <div className="flex md:w-[350px]">
              <input
                type="checkbox"
                className="w-5 h-5 mr-4 accent-blue-500"
                checked={instructionRequired}
                onChange={() =>
                  setInstructionRequired((prevState: boolean) => !prevState)
                }
              />

              <p>{t('prompts.form.instruction')}</p>
            </div>

            <div className="flex flex-col flex-grow w-full md:w-auto">
              <textarea
                className={`form-textarea w-full  ${errors.instruction ? 'error' : ''}`}
                placeholder={t('prompts.form.instructionPlaceholder')}
                {...instructionField}
              />
              {errors.instruction && (
                <p className="form-error mt-10px">
                  {errors.instruction.type === 'required'
                    ? t('global.form.error.mandatory')
                    : errors.instruction.message}
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
