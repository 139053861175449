import { useController, useForm } from 'react-hook-form'
import { IPromptViolation, PromptForm } from 'interfaces/Prompt'
import { toast } from 'components/common/Toast'
import { useCreateUpdatePromptMutation, useGetPromptQuery } from 'api/prompt'
import { generatePath, useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { MutableRefObject, useEffect, useRef, useState } from 'react'
import { skipToken } from '@reduxjs/toolkit/query'
import { useParams } from 'react-router-dom'
import { ROUTE_PATHS } from 'constants/routePath'
import { useSelector } from 'react-redux'
import { PromptLayoutRootState } from 'store/slices/promptLayout'
import { useLayoutApplication } from 'hooks/useLayoutApplication'
import { usePromptDocumentManagement } from 'hooks/usePromptDocumentManagement'
import { useGetAllModelesQuery } from 'api/referentielModeles'

export const useCreatePromptForm = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { idPrompt } = useParams()
  const [open, setOpen] = useState<boolean>(false)
  const [assistedMode, setAssistedMode] = useState<boolean>(true)
  const [instructionRequired, setInstructionRequired] = useState<boolean>(true)
  const { documents, setDocuments, newFiles, setNewFiles } =
    usePromptDocumentManagement()
  const { data: modeles, isLoading: isLoadingModeles } = useGetAllModelesQuery()
  const currentApplication = useSelector(
    (state: PromptLayoutRootState) => state.promptLayout.application
  )
  const promptId = idPrompt ? parseInt(idPrompt, 10) : undefined
  const {
    data: prompt,
    isLoading: isLoadingPrompt,
    refetch,
    isFetching,
  } = useGetPromptQuery(promptId ? { promptId } : skipToken)
  const initialValues = prompt || {
    nomPrompt: '',
    idPrompt: '',
    prompt: '',
    role: '',
    tache: '',
    contexte: '',
    actif: false,
    formApplication: '',
    format: '',
    instruction: t('prompts.form.instructionPlaceholder'),
    modele: '',
    temperature: 0.5,
  }
  const {
    handleSubmit,
    setError,
    formState: { errors },
    control,
    reset,
    getValues,
    setValue,
    register,
    clearErrors,
  } = useForm<PromptForm>({
    defaultValues: initialValues,
  })
  const { refetch: refetchApplication } = useLayoutApplication()

  useEffect(() => {
    if (prompt) {
      reset({
        idApplication: prompt.idApplication,
        nomPrompt: prompt.nomPrompt,
        prompt: prompt.prompt,
        actif: prompt.actif,
      })
      setAssistedMode(!!prompt.role)
      setDocuments(prompt.documents || [])
      setInstructionRequired(!!prompt.instruction)
    }
  }, [prompt, reset])

  const cancel = (event: any) => {
    event.preventDefault()
    navigate(
      generatePath(idPrompt ? ROUTE_PATHS.PROMPT : ROUTE_PATHS.PROMPTS, {
        idApplication: currentApplication?.id,
        idPrompt: promptId,
      })
    )
  }

  const handleSubmitForm = (data: any) => {
    //Si l’application ne dispose d’aucun Prompt actif, ce dernier sera “ACTIF” par défaut
    if (!currentApplication?.prompts.find((p) => p.actif) || promptId) {
      onSubmit(data, true)
    } else {
      setOpen(true)
    }
  }

  const promptRef = useRef<HTMLTextAreaElement | null>(null)
  const fields = {
    nomField: useController({
      name: 'nomPrompt',
      control,
      rules: { required: true },
    }).field,
    promptField: useController({
      name: 'prompt',
      control,
      rules: { required: !assistedMode },
    }).field,
    roleField: useController({
      name: 'role',
      control,
      rules: { required: assistedMode },
    }).field,
    tacheField: useController({
      name: 'tache',
      control,
      rules: { required: assistedMode },
    }).field,
    contexteField: useController({
      name: 'contexte',
      control,
      rules: { required: assistedMode },
    }).field,
    formatField: useController({
      name: 'format',
      control,
      rules: { required: assistedMode },
    }).field,
    instructionField: useController({
      name: 'instruction',
      control,
      rules: { required: assistedMode && instructionRequired },
    }).field,
    temperatureField: useController({
      name: 'temperature',
      control,
      rules: { required: false },
    }).field,
    modeleField: useController({
      name: 'modele',
      control,
      rules: { required: true },
    }).field,
  }
  const tacheRef = useRef<HTMLTextAreaElement | null>(null)

  const [createUpdatePrompt, { isLoading }] = useCreateUpdatePromptMutation()

  const addTextToTextarea = (
    event: any,
    text: string,
    ref: MutableRefObject<any>,
    fieldName: any
  ) => {
    event.preventDefault()
    const textarea = ref.current
    if (textarea) {
      const { selectionStart, selectionEnd, value } = textarea
      const newValue =
        selectionStart || selectionStart === 0
          ? value.substring(0, selectionStart) +
            text +
            value.substring(selectionEnd)
          : value + text
      setValue(fieldName, newValue)
    }
  }

  const changeMode = (isAssisted: boolean) => {
    setAssistedMode(isAssisted)
    if (!isAssisted) {
      const assistedValues = [
        getValues('role'),
        getValues('tache'),
        getValues('format'),
        getValues('contexte'),
        getValues('instruction'),
      ]
      setValue('prompt', assistedValues.filter(Boolean).join('\n'))
      setValue('role', '')
      setValue('tache', '')
      setValue('contexte', '')
      setValue('format', '')
      setValue('instruction', '')
    } else {
      setValue('tache', getValues('prompt'))
      setValue('prompt', '')
    }
  }

  const onSubmit = (data: any, actif = false, redirectManage = false) => {
    const formData = new FormData()
    formData.append('nomPrompt', data.nomPrompt)
    formData.append('prompt', data.prompt)
    formData.append('temperature', data.temperature)
    formData.append('modele', data.modele)
    if (currentApplication?.id) {
      formData.append('idApplication', currentApplication?.id.toString())
    }
    const docIds =
      documents
        .filter((doc) => doc.id !== undefined)
        .map((doc) => doc.id?.toString()) ?? []
    formData.append('documents', JSON.stringify(docIds))
    const documentNames = documents.map((doc) => doc.nomDocument)
    if (newFiles.length === 0) {
      formData.append('files', JSON.stringify([]))
    } else {
      newFiles.forEach((file) => {
        if (documentNames.length === 0 || !documentNames.includes(file.name)) {
          formData.append('files', JSON.stringify([]))
        } else {
          formData.append('files[]', file)
        }
      })
    }
    if (assistedMode) {
      formData.append('role', data.role)
      formData.append('tache', data.tache)
      formData.append('contexte', data.contexte)
      formData.append('format', data.format)
      if (instructionRequired) {
        formData.append('instruction', data.instruction)
      }
      formData.append('prompt', '')
    }
    // mise à jour
    if (promptId) {
      formData.append('idPrompt', promptId.toString())
      createUpdatePrompt({ data: formData })
        .unwrap()
        .then((prompt) => {
          if (prompt.id) {
            navigate(
              generatePath(ROUTE_PATHS.PROMPT, {
                idApplication: currentApplication?.id,
                idPrompt: prompt.id,
              })
            )
            refetch()
            refetchApplication()
            toast.success(t('prompts.form.successUpdate'))
          }
        })
        .catch((error) => {
          if (typeof error.data.violations !== 'undefined') {
            const violations: IPromptViolation[] = error.data.violations

            for (let violation of violations) {
              if (violation.propertyPath === 'idApplication') {
                navigate(ROUTE_PATHS.ERROR)
              } else {
                setError(violation.propertyPath, {
                  type: 'custom',
                  // @todo : mettre en place des code de traductions
                  message: violation.message,
                })
              }
            }
          }
        })
    } else {
      // creation
      formData.append('actif', actif.toString())
      formData.append('distribution', '0')
      createUpdatePrompt({ data: formData })
        .unwrap()
        .then((prompt) => {
          if (prompt.id) {
            if (redirectManage) {
              navigate(
                generatePath(ROUTE_PATHS.DISTRIBUTION, {
                  idApplication: currentApplication?.id,
                })
              )
            } else {
              navigate(
                generatePath(ROUTE_PATHS.PROMPT, {
                  idApplication: currentApplication?.id,
                  idPrompt: prompt.id,
                })
              )
            }
          }
          refetchApplication()
          toast.success(t('prompts.form.success'))
        })
        .catch((error) => {
          if (typeof error.data.violations !== 'undefined') {
            const violations: IPromptViolation[] = error.data.violations

            for (let violation of violations) {
              if (violation.propertyPath === 'idApplication') {
                navigate(ROUTE_PATHS.ERROR)
              } else {
                setError(violation.propertyPath, {
                  type: 'custom',
                  // @todo : mettre en place des code de traductions
                  message: violation.message,
                })
              }
            }
          }
        })
    }
  }

  const onFormSubmit = handleSubmit(handleSubmitForm)

  const state = {
    isLoading: isLoading || isLoadingPrompt || isFetching || isLoadingModeles,
    open,
    assistedMode,
    documents,
    newFiles,
  }

  const setters = {
    setOpen,
    setDocuments,
    setNewFiles,
    setAssistedMode,
  }

  return {
    state,
    setters,
    fields,
    promptRef,
    tacheRef,
    addTextToTextarea,
    errors,
    onFormSubmit,
    handleConfirm: () => onSubmit(getValues(), true, true),
    handleCancel: () => onSubmit(getValues(), false),
    cancel,
    changeMode,
    setValue,
    modeles,
    register,
    setError,
    clearErrors,
    instructionRequired,
    setInstructionRequired,
  }
}
