import { baseApi } from 'api/base'
import { IAskBody, IAskResponse } from 'interfaces/Ask'

const ASK_ENDPOINT = '/ask'

const askApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    ask: builder.mutation<IAskResponse, IAskBody>({
      query: (body) => ({
        url: ASK_ENDPOINT,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: [],
    }),
  }),
})

export const { useAskMutation } = askApi

export { askApi }
