import { IPromptDetail } from 'interfaces/Prompt'
import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { PromptDiffusionCard } from 'components/prompts/PromptDiffusionCard'
import { DeletePromptButton } from 'components/prompts/DeletePromptButton'
import { DistributionInput } from 'components/prompts/DistributionInput'
import { DisplayStarRating } from 'components/common/DisplayStarRating'

export const PromptDistributionRow: React.FC<{
  prompt: IPromptDetail
  setPromptData: Dispatch<SetStateAction<IPromptDetail[] | undefined>>
  isDeleted: boolean
  setPromptToDelete: Dispatch<SetStateAction<IPromptDetail | null>>
  setOpenModal: Dispatch<SetStateAction<boolean>>
  error: boolean
}> = ({
  prompt,
  setPromptData,
  isDeleted,
  setPromptToDelete,
  setOpenModal,
  error,
}) => {
  const { t } = useTranslation()
  const handleDistributionChange = (id: number, value: number) => {
    setPromptData((prevData) =>
      prevData?.map((prompt) =>
        prompt.id === id ? { ...prompt, distribution: value } : prompt
      )
    )
  }
  const changePromptStatus = (promptToChange: IPromptDetail) => {
    setPromptData((prevData) =>
      prevData?.map((prompt) =>
        prompt.id === promptToChange.id
          ? { ...prompt, actif: !promptToChange.actif }
          : prompt
      )
    )
  }
  const handleDelete = () => {
    if (!prompt.actif) {
      setPromptToDelete(prompt)
      setOpenModal(true)
    }
  }
  return (
    <div className="flex md:flex-row flex-col items-center w-full md:mb-0 mb-30px">
      <div className="w-full md:w-5/12 md:pr-30px md:mb-[5px]">
        <PromptDiffusionCard prompt={prompt} isDeleted={isDeleted} />
      </div>
      <div className="grid grid-cols-12 md:gap-10 w-full md:w-7/12 md:mt-0 mt-[5px] items-center">
        <div className="md:col-span-2 col-span-4 md:ml-10px content-center">
          <button
            onClick={() => changePromptStatus(prompt)}
            className={`prompt-status-btn ${isDeleted ? 'deleted' : prompt.actif ? 'actif' : 'inactif'}`}
          >
            {prompt.actif ? t('prompts.actif') : t('prompts.inactif')}
          </button>
        </div>
        <div
          className={`inline content-center md:justify-items-start justify-items-center md:col-span-3 col-span-4 md:ml-10px ${isDeleted ? 'text-gray-1225' : ''}`}
        >
          {prompt.actif && (
            <DistributionInput
              handleDistributionChange={handleDistributionChange}
              prompt={prompt}
              error={error}
            />
          )}
        </div>
        <div
          className={`col-span-4 flex flex-row items-center  ${isDeleted ? 'text-gray-1200' : !prompt.actif ? 'text-gray-1025' : prompt.note === null || prompt.note === 0 ? 'text-gray-1225' : 'text-blue-1350'}`}
        >
          <DisplayStarRating note={prompt.note} className="hidden md:block" />
          <span className="ml-15px text-[18px] hidden md:block">
            {prompt.note ? prompt.note : t('prompts.diffusion.nonNote')}
          </span>
          <span className="md:hidden block">
            {t('prompts.diffusion.evaluation')}:&nbsp;
            {prompt.note ? prompt.note : t('prompts.diffusion.nonNote')}
          </span>
        </div>
        <div className="hidden md:block md:col-span-3">
          {isDeleted ? (
            t('prompts.diffusion.textDeleted')
          ) : (
            <DeletePromptButton prompt={prompt} handleDelete={handleDelete} />
          )}
        </div>
      </div>
    </div>
  )
}
