import { generatePath, Outlet, useNavigate } from 'react-router'
import React, { FC } from 'react'
import Loader from 'components/common/Loader'
import { ApplicationHeader } from 'components/applications/ApplicationHeader'
import { useLayoutApplication } from 'hooks/useLayoutApplication'
import { DarkModeProvider } from 'contexts/DarkThemeContext'
import { ReactComponent as LogoLauria } from 'assets/images/logoLauria.svg'
import { DarkThemeToggle } from 'components/layout/DarkThemeToggle'
import { CustomToastContainer } from 'components/common/Toast'

export const LayoutApplication: FC = () => {
  const navigate = useNavigate()
  const { application, isLoading } = useLayoutApplication()
  const goToDashboard = () => {
    navigate(generatePath('/'))
  }

  return (
    <DarkModeProvider>
      <div className="layout-container">
        {application && !isLoading ? (
          <>
            <div className="layout-header md:min-h-[80px]">
              <div className="flex-shrink-0">
                <LogoLauria className="logo-lauria" onClick={goToDashboard} />
              </div>
              <div className="hidden md:flex items-center">
                <ApplicationHeader application={application} />
              </div>
              <div className="flex-shrink-0">
                <DarkThemeToggle />
              </div>
            </div>
            <Outlet />
          </>
        ) : (
          <div className="w-full h-full flex items-center justify-center">
            <Loader pageCenter />
          </div>
        )}
        <CustomToastContainer />
      </div>
    </DarkModeProvider>
  )
}
