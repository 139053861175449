import { KpiCard } from 'components/kpi/KpiCard'
import { kpiMetrics } from 'constants/kpi'

export const KpiSideBar = () => {
  return (
    <div className="kpi-sidebar">
      {kpiMetrics.map((metric, index) => {
        return <KpiCard key={index} metric={metric} />
      })}
    </div>
  )
}
