import React, { useEffect, useContext, Dispatch, SetStateAction } from 'react'
import { format, add, sub } from 'date-fns'
import { fr } from 'date-fns/locale'
import { PERIOD } from 'constants/period'
import { Trans, useTranslation } from 'react-i18next'
import { ReactComponent as PrevIcon } from 'assets/images/prevIcon.svg'
import { ReactComponent as NextIcon } from 'assets/images/nextIcon.svg'
import { ReactComponent as PrevIconHover } from 'assets/images/prevIconHover.svg'
import { ReactComponent as NextIconHover } from 'assets/images/nextIconHover.svg'
import { ReactComponent as PrevIconDark } from 'assets/images/prevIconDark.svg'
import { ReactComponent as NextIconDark } from 'assets/images/nextIconDark.svg'
import { DarkThemeContext } from 'contexts/DarkThemeContext'
import { getPeriodDates } from 'utils/service'

export const PeriodSelector: React.FC<{
  periodState: { period: string; setPeriod: Dispatch<SetStateAction<string>> }
  dateRange: {
    currentDate: Date
    setCurrentDate: Dispatch<SetStateAction<Date>>
    start: Date
    setStart: Dispatch<SetStateAction<Date>>
    end: Date
    setEnd: Dispatch<SetStateAction<Date>>
  }
}> = ({ periodState, dateRange }) => {
  const { t } = useTranslation()
  const { isDarkTheme } = useContext(DarkThemeContext)
  const { period, setPeriod } = periodState
  const { currentDate, setCurrentDate, start, setStart, end, setEnd } =
    dateRange

  const handleChange = (next: boolean) => {
    switch (period) {
      case PERIOD.HEBDO:
        setCurrentDate(
          next ? add(currentDate, { weeks: 1 }) : sub(currentDate, { weeks: 1 })
        )
        break
      case PERIOD.MENSUELLE:
        setCurrentDate(
          next
            ? add(currentDate, { months: 1 })
            : sub(currentDate, { months: 1 })
        )
        break
      case PERIOD.SEMESTRIELLE:
        setCurrentDate(
          next
            ? add(currentDate, { months: 6 })
            : sub(currentDate, { months: 6 })
        )
        break
      case PERIOD.ANNUELLE:
        setCurrentDate(
          next ? add(currentDate, { years: 1 }) : sub(currentDate, { years: 1 })
        )
        break
    }
  }
  useEffect(() => {
    const [startDate, endDate] = getPeriodDates(period, currentDate)
    setStart(startDate)
    setEnd(endDate)
  }, [period, currentDate])

  return (
    <div className="period-selector">
      <div className="period-selector-dropdown">
        <span>{t('prompts.kpi.period.label')}</span>
        <select
          className="dropdown-period form-input h-[30px]"
          value={period}
          onChange={(e) => setPeriod(e.target.value as string)}
        >
          <option value={PERIOD.HEBDO}>{t('prompts.kpi.period.hebdo')}</option>
          <option value={PERIOD.MENSUELLE}>
            {t('prompts.kpi.period.mensuelle')}
          </option>
          <option value={PERIOD.SEMESTRIELLE}>
            {t('prompts.kpi.period.semestrielle')}
          </option>
          <option value={PERIOD.ANNUELLE}>
            {t('prompts.kpi.period.annuelle')}
          </option>
        </select>
      </div>
      <div className="controls">
        <button
          onClick={() => handleChange(false)}
          className="button-selector group"
        >
          {isDarkTheme ? (
            <PrevIconDark className="button-selector-icon opacity-100 group-hover:opacity-0" />
          ) : (
            <PrevIcon className="button-selector-icon opacity-100 group-hover:opacity-0" />
          )}
          <PrevIconHover className="button-selector-icon opacity-0 group-hover:opacity-100 text-blue-1050 dark:text-gray-1450" />
        </button>
        <span>
          {period === PERIOD.HEBDO ? (
            t('prompts.kpi.period.textHebdo') +
            format(start, 'dd/MM/yyyy', { locale: fr })
          ) : period === PERIOD.MENSUELLE ? (
            <span className="capitalize">
              {format(currentDate, 'MMMM yyyy', { locale: fr })}
            </span>
          ) : (
            <Trans
              i18nKey="prompts.kpi.period.textMois"
              values={{
                date1: format(start, 'MMMM yyyy', { locale: fr }),
                date2: format(end, 'MMMM yyyy', { locale: fr }),
              }}
            />
          )}
        </span>
        <button
          onClick={() => handleChange(true)}
          className="button-selector group"
        >
          {isDarkTheme ? (
            <NextIconDark className="button-selector-icon opacity-100 group-hover:opacity-0" />
          ) : (
            <NextIcon className="button-selector-icon opacity-100 group-hover:opacity-0" />
          )}

          <NextIconHover className="button-selector-icon opacity-0 group-hover:opacity-100 text-blue-1050 dark:text-gray-1450" />
        </button>
      </div>
    </div>
  )
}
