import { baseApi } from 'api/base'
import { generatePath } from 'react-router'
import { IKpiRequest, IKpiResponse } from 'interfaces/Kpi'

const KPI_ENDPOINT = '/kpi'
const kpiApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getKpis: builder.mutation<Record<string, IKpiResponse[]>, IKpiRequest>({
      query: (body) => ({
        url: generatePath(KPI_ENDPOINT),
        method: 'POST',
        body: body,
      }),
    }),
  }),
})
export const { useGetKpisMutation } = kpiApi
export { kpiApi }
