import React, { useContext } from 'react'
import { PromptCard } from 'components/applications/PromptCard'
import { generatePath, useNavigate } from 'react-router'
import { ROUTE_PATHS } from 'constants/routePath'
import { NewPromptCard } from 'components/applications/NewPromptCard'
import { IPrompt } from 'interfaces/Prompt'
import { useSelector } from 'react-redux'
import { promptLayout } from 'store/slices/promptLayout'
import { AddButton } from 'components/common/AddButton'
import { useTranslation } from 'react-i18next'
import { ReactComponent as PlusButton } from 'assets/images/plusButton.svg'
import { ReactComponent as PlusButtonDark } from 'assets/images/plusButtonDark.svg'
import { DarkThemeContext } from 'contexts/DarkThemeContext'

interface PromptCardListProps {
  prompts: IPrompt[]
}

export const PromptCardList: React.FC<PromptCardListProps> = ({ prompts }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { application } = useSelector(promptLayout)
  const { isDarkTheme } = useContext(DarkThemeContext)

  const displayPrompt = (id?: number) => {
    navigate(
      generatePath(ROUTE_PATHS.PROMPT, {
        idApplication: application?.id,
        idPrompt: id,
      })
    )
  }

  const createPrompt = () => {
    navigate(
      generatePath(ROUTE_PATHS.PROMPT_CREATE, {
        idApplication: application?.id,
      })
    )
  }

  return (
    <div className="md:px-30px md:pt-30px pt-[10px]">
      {prompts.length === 0 ? (
        <NewPromptCard onClick={createPrompt} />
      ) : (
        <div className="card-list">
          {prompts
            .filter((prompt) => !prompt.suppression)
            .map((prompt) => (
              <PromptCard
                key={prompt.id}
                prompt={prompt}
                handleClick={() => displayPrompt(prompt.id)}
              />
            ))}
          <div className="add-prompt group" onClick={createPrompt}>
            <AddButton />
            <span className="ml-[15px] font-medium">
              {t('prompts.newPrompt')}
            </span>
          </div>
          <div
            className="md:hidden fixed bottom-3.5 right-3.5"
            onClick={createPrompt}
          >
            {isDarkTheme ? <PlusButtonDark /> : <PlusButton />}
          </div>
        </div>
      )}
    </div>
  )
}
