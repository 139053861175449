import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
  useBatchPromptsMutation,
  useGetPromptsWithDetailsFromApplicationQuery,
} from 'api/prompt'
import { skipToken } from '@reduxjs/toolkit/query'
import { IPromptDetail } from 'interfaces/Prompt'
import { useLayoutApplication } from 'hooks/useLayoutApplication'

export const usePromptDistribution = () => {
  const { idApplication } = useParams()
  const [promptData, setPromptData] = useState<IPromptDetail[] | undefined>([])
  const [promptDeleted, setPromptDeleted] = useState<IPromptDetail[]>([])
  const [promptToDelete, setPromptToDelete] = useState<IPromptDetail | null>(
    null
  )
  const [showInactive, setShowInactive] = useState(false)
  const [openRemoveModal, setOpenRemoveModal] = useState(false)
  const [error, setError] = useState(false)

  const {
    data: prompts,
    isLoading,
    refetch,
  } = useGetPromptsWithDetailsFromApplicationQuery(
    idApplication ? { applicationId: parseInt(idApplication) } : skipToken
  )
  const [batchPrompts, { isLoading: isSaving }] = useBatchPromptsMutation()
  const { refetch: refetchApplication } = useLayoutApplication()

  useEffect(() => {
    setPromptData(prompts)
  }, [prompts])

  useEffect(() => {
    const activePrompts = promptData?.filter(
      (prompt) => prompt.actif && !prompt.suppression
    )
    if (activePrompts?.length === 0) {
      setShowInactive(true)
    }
  }, [promptData])

  const activePrompts = promptData?.filter(
    (prompt) => prompt.actif && !prompt.suppression
  )
  const inactivePrompts = promptData?.filter(
    (prompt) => !prompt.actif && !prompt.suppression
  )

  const totalDistribution =
    activePrompts?.reduce(
      (sum, prompt) => sum + (prompt.distribution || 0),
      0
    ) ?? 0

  const removePrompt = () => {
    if (promptToDelete) {
      setPromptDeleted([...promptDeleted, promptToDelete])
      setPromptToDelete(null)
      setOpenRemoveModal(false)
    }
  }

  const savePrompts = () => {
    if (
      totalDistribution !== 100 &&
      activePrompts &&
      activePrompts.length > 0
    ) {
      setError(true)
      return
    } else {
      setError(false)
      const promptsDeletedIds = promptDeleted?.map(({ id }) => id) || []

      const filteredPromptData =
        promptData?.filter(
          (prompt) => !promptsDeletedIds.includes(prompt.id)
        ) ?? []

      batchPrompts({
        toDelete: promptsDeletedIds,
        toUpdate: filteredPromptData.map((prompt) => ({
          id: prompt.id,
          actif: prompt.actif,
          distribution: prompt.distribution,
        })),
      }).then(() => {
        setPromptToDelete(null)
        setPromptDeleted([])
        refetchApplication()
        refetch()
      })
    }
  }

  return {
    setPromptData,
    promptDeleted,
    setPromptToDelete,
    activePrompts,
    inactivePrompts,
    showInactive,
    setShowInactive,
    openRemoveModal,
    setOpenRemoveModal,
    error,
    isLoading,
    isSaving,
    totalDistribution,
    removePrompt,
    savePrompts,
  }
}
