import {
  DEFAULT_APP_COLOR,
  DEFAULT_APP_DARK_COLOR,
  DEFAULT_APP_ICONE,
  DEFAULT_APP_ICONE_DARK,
} from 'constants/general'
import { IApplication, IApplicationService } from 'interfaces/Application'
import { IServiceGroupedApplications } from 'interfaces/Service'
import { PERIOD } from 'constants/period'
import { add, startOfMonth, startOfWeek, sub } from 'date-fns'

export const getIconeInfo = (service: IApplicationService) => {
  let color, colorDark, svgPath, svgPathDark, imageSmall, imageSmallDark
  try {
    const parsedIcon = service && JSON.parse(service?.icone)
    if (parsedIcon) {
      ;({ color, colorDark, imageSmall, imageSmallDark } = parsedIcon)
    }
    svgPath = require(`assets/images/${imageSmall}`)
    svgPathDark = require(`assets/images/${imageSmallDark}`)
  } catch (error) {
    color = DEFAULT_APP_COLOR
    colorDark = DEFAULT_APP_DARK_COLOR
    svgPath = require(`assets/images/${DEFAULT_APP_ICONE}`)
    svgPathDark = require(`assets/images/${DEFAULT_APP_ICONE_DARK}`)
  }

  return { color, colorDark, svgPath, svgPathDark }
}

export const groupByService = (applications: IApplication[]) => {
  return applications.reduce((acc, app) => {
    const serviceName = app.service.nomService
    const serviceIcone = getIconeInfo(app.service)
    const newAcc = { ...acc }
    if (!newAcc[serviceName]) {
      newAcc[serviceName] = {
        service: serviceIcone,
        apps: [],
      }
    }
    newAcc[serviceName].apps.push(app)

    return newAcc
  }, {} as IServiceGroupedApplications)
}

export const getPeriodDates = (
  period: string,
  currentDate: Date
): [Date, Date] => {
  const periodMap = {
    [PERIOD.HEBDO]: [
      startOfWeek(currentDate, { weekStartsOn: 1 }),
      add(startOfWeek(currentDate, { weekStartsOn: 1 }), { days: 6 }),
    ],
    [PERIOD.MENSUELLE]: [
      startOfMonth(currentDate),
      add(startOfMonth(currentDate), { months: 1, days: -1 }),
    ],
    [PERIOD.SEMESTRIELLE]: [add(currentDate, { months: -5 }), currentDate],
    [PERIOD.ANNUELLE]: [sub(currentDate, { months: 12 }), currentDate],
  } as Record<string, [Date, Date]>
  return periodMap[period] ?? [currentDate, currentDate]
}
