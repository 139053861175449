import { IPromptDetail } from 'interfaces/Prompt'
import { ReactComponent as PromptIconBanner } from 'assets/images/prompts/promptIconBanner.svg'
import { ReactComponent as PromptIconBannerDark } from 'assets/images/prompts/promptIconBannerDark.svg'
import { ReactComponent as PromptIconBannerDeleted } from 'assets/images/prompts/promptIconBannerDeleted.svg'
import { ReactComponent as PromptIconBannerDeletedDark } from 'assets/images/prompts/promptIconBannerDeletedDark.svg'
import { ReactComponent as CheckedIcon } from 'assets/images/checkedIcon.svg'
import { DarkThemeContext } from 'contexts/DarkThemeContext'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

export const KpiPromptCard: React.FC<{
  prompt: IPromptDetail
  classname?: string
  selected?: boolean
  error?: boolean
  deleted?: boolean
  handleClick: () => void
}> = ({
  prompt,
  classname = '',
  selected = false,
  error = false,
  deleted = false,
  handleClick,
}) => {
  const { t } = useTranslation()
  const { isDarkTheme } = useContext(DarkThemeContext)

  return (
    <div className="flex flex-col w-[280px] mr-[20px]">
      <div
        className={`kpi-prompt-card ${classname} ${error ? 'error' : ''}`}
        onClick={handleClick}
      >
        {selected && <div className={`left-border ${classname}`}></div>}
        <div
          className={`kpi-prompt-card-content ${selected ? 'absolute left-[8px] ' + classname : ''}`}
        >
          <div className="flex items-center">
            {isDarkTheme ? (
              deleted ? (
                <PromptIconBannerDeletedDark className="kpi-prompt-icon" />
              ) : (
                <PromptIconBannerDark className="kpi-prompt-icon" />
              )
            ) : deleted ? (
              <PromptIconBannerDeleted className="kpi-prompt-icon" />
            ) : (
              <PromptIconBanner className="kpi-prompt-icon" />
            )}
            <div className="uppercase line-clamp-2">{prompt.nomPrompt}</div>
          </div>

          {selected && (
            <div className="flex items-center justify-center">
              <CheckedIcon className="text-blue-1500" />
            </div>
          )}
        </div>
      </div>
      {error && (
        <div className="kpi-prompt-card-error">
          {t('prompts.kpi.errorSelection')}
        </div>
      )}
    </div>
  )
}
